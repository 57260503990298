<script lang="ts">
    import * as Dialog from '$lib/components/ui/dialog';
    import { Button } from '$lib/components/ui/button';

    export let show = false;
    export let user;
</script>

<Dialog.Root bind:open="{show}">
    <Dialog.Content class="sm:max-w-[900px]">
        <Dialog.Header>
            <Dialog.Title>Login to subscribe</Dialog.Title>
        </Dialog.Header>

        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div class="hidden items-center justify-center md:flex">
                <!-- Member card component would go here -->
                {#if user}
                    <div class="card-wrapper">
                        <!-- Vertical member card component here -->
                    </div>
                {/if}
            </div>

            <div class="flex items-center">
                <!-- Auth forms component here -->
            </div>
        </div>
    </Dialog.Content>
</Dialog.Root>
